import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgInsertionCover from "../../assets/images/journey/mars-orbit-insertion/insertion-cover.png"

const CruisePage = () => (
  <Layout lang="ar">
    <SEO title="Mars Orbit Insertion" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #88370C"}}>
        <img src={imgInsertionCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  الدخول إلى مدار المريخ
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  عند بداية هذه المرحلة سيتم تقليل العمليات في المحطة الأرضية والتواصل مع "مسبار الأمل" إلى الحد الأدنى، ليتحول تركيز الفريق إلى إدخال المسبار في مدار التقاط حول المريخ بشكل آمن. وسيتم حرق نصف كمية الوقود الموجودة في خزانات "مسبار الأمل" لإبطائه إلى الحد الذي يسمح بإدخاله في مدار الالتقاط. وستستمر عملية حرق الوقود (إطلاق صواريخ دلتا في) لمدة 30 دقيقة لتقليل سرعة المسبار من 121,000 كم/ساعة إلى 18,000 كم/ساعة.
                  </p>
                  <p>
                  وستتم عملية الدخول إلى مدار الالتقاط حول المريخ، بشكل مستقل 100%، دون تدخل من فريق مشروع الإمارات لاستكشاف المريخ الذي لن يتمكن من التعامل مع المسبار بسبب تأخر الإشارات اللاسلكية الصادرة منه التي سوف تستغرق مدة تتراوح بين 13 إلى 26 دقيقة للوصول إلى الأرض. وبمجرد اكتمال عملية الدخول إلى مدار المريخ سيكون "مسبار الأمل" محجوباً بواسطة المريخ، وعندما يخرج المسبار من الجانب المظلم للمريخ، سيتم إعادة الاتصال به مرة أخرى وعندها فقط يمكن للفريق التأكد من نجاح مناورة الدخول إلى مدار الالتقاط حول المريخ. 
                  </p>
                  <p>
                  وخلال الأشهر القليلة التالية، سيقوم الفريق بفحص الأجهزة العلمية الموجودة على متن "مسبار الأمل"، ويتم إجراء عدة مناورات لإدخاله في المدار العلمي الصحيح حول المريخ حتى يبدأ مهمته.
                  </p>
                </div>
            </div>
                     
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
